import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbOptionModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule
} from '@nebular/theme';


const NB_MODULES = [
  NbLayoutModule,
  NbUserModule,
  NbActionsModule,
  NbCardModule,
  NbInputModule,
  NbSearchModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbIconModule,
  NbEvaIconsModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbCheckboxModule,
  NbStepperModule,
  NbAutocompleteModule,
  NbToggleModule,
  NbAccordionModule,
  NbRadioModule,
  NbButtonGroupModule,
  NbFormFieldModule,
  NbBadgeModule,
  NbListModule,
  NbOptionModule,
  NbPopoverModule,
  NbCalendarRangeModule,
  NbProgressBarModule,
  NbMomentDateModule,
];

@NgModule({
  imports: [
    ...NB_MODULES,
  ],
  exports: [
    ...NB_MODULES
  ],
})
export class ThemeModule { }
